// 
// form-check.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// Cursor pointer
.form-check {
  .form-check-input:focus{
    box-shadow: none;
  }
  .form-check-input, .form-check-label  {
    cursor: pointer;
  }
}

// form switch size
.form-switch {
  .form-check-input:focus{
    box-shadow: none;
  }
  &.form-check-md{
    .form-check-input{
      height: 1.3em;
      width: 2.4em;
    }
  }
  &.form-check-lg{
    .form-check-input{
      height: 2em;
      width: 4em;
      margin-top: 0.15em;
      .form-check-input{
        width: 3.4em;
      }
    }
  }
}

.form-check-input.focus-shadow-none:focus{
  box-shadow: none;
}
// 
// dark-mode.scss
// Theme Component
// Dark mode related CSS

[data-bs-theme="dark"] {

  // //Dark mode styles
    --bs-gray-100: #101521;
    --bs-gray-200: #1c2130;
    --bs-gray-300: #5f5f68;
    --bs-gray-400: #a1a1a8;
    --bs-gray-500: #bfc0c9;
    --bs-gray-600: #CFD0D4;
    --bs-gray-700: #ffffffb3;
    --bs-gray-800: #ffffffd9;
    --bs-gray-900: #fff;
    --bs-body-bg: #0b0f1a;
    --bs-body-bg-rgb: 11, 15, 26;
    --bs-body-color: #c4c5c7;
    --bs-heading-color: var(--bs-gray-900);
    --bs-secondary: #1f2434;
    --bs-secondary-bg-rgb: 31, 36, 52;
    --bs-border-color: rgba(255, 255, 255, 0.07);

    body, p, ul, ol {
      color: var(--#{$prefix}body-color);
    }

    .navbar {
    --#{$prefix}-navbar-color: var(--#{$prefix}gray-800);
    --#{$prefix}-navbar-hover-color: var(--#{$prefix}primary);
    --#{$prefix}-navbar-disabled-color: var(--#{$prefix}gray-400);
    --#{$prefix}-navbar-active-color: var(--#{$prefix}primary);
    }
    .nav{
      --bs-nav-link-color: var(--#{$prefix}gray-600);
    }

    .dropdown-menu {
      --#{$prefix}-dropdown-bg: var(--bs-gray-100);
      --#{$prefix}-dropdown-link-hover-color: var(--#{$prefix}primary);
      --#{$prefix}-dropdown-link-active-color: var(--#{$prefix}primary);
      --#{$prefix}-dropdown-link-disabled-color: var(--#{$prefix}gray-200);
      box-shadow:none;
      border: 1px solid var(--#{$prefix}border-color);
    }

    .btn-link {
      --bs-link-color: var(--#{$prefix}primary);
      --bs-btn-hover-color: var(--#{$prefix}link-hover-color);
      --bs-btn-active-color: var(--#{$prefix}link-hover-color);
    }

    .btn-secondary {
      --#{$prefix}btn-color: rgba(#{to-rgb($white)});
      --#{$prefix}btn-hover-color: #{$gray-200};
      --#{$prefix}btn-active-color: #{$gray-200};
      --#{$prefix}btn-border-color: transparent;
      --#{$prefix}btn-hover-border-color: transparent;
      --#{$prefix}btn-active-border-color: transparent;
      --#{$prefix}btn-bg: rgba(#{to-rgb($gray-200)}, .06);
      --#{$prefix}btn-hover-bg: rgba(#{to-rgb($gray-200)}, .13);
      --#{$prefix}btn-active-bg: rgba(#{to-rgb($gray-200)}, .13);
      --#{$prefix}btn-disabled-bg: rgba(#{to-rgb($gray-200)}, .05);
      --#{$prefix}btn-disabled-border-color: transparent;
    }

    .btn-dark {
      --#{$prefix}btn-color: #{$gray-100} !important;
      --#{$prefix}btn-hover-color: #{$gray-100} !important;
      --#{$prefix}btn-active-color: #{$gray-100} !important;
      --#{$prefix}btn-bg: #{darken($gray-900, 9%)};
      --#{$prefix}btn-border-color: #{darken($gray-900, 9%)};
      --#{$prefix}btn-hover-bg: #{darken($gray-800, 4%)};
      --#{$prefix}btn-active-bg: #{darken($gray-800, 4%)};
      --#{$prefix}btn-hover-border-color: #{darken($gray-800, 4%)};
      --#{$prefix}btn-active-border-color: #{darken($gray-800, 4%)};
    }

    .modal {
      --#{$prefix}-modal-bg: var(--#{$prefix}body-bg);
      --#{$prefix}-modal-border-color: var(--#{$prefix}border-color-translucent);
      --#{$prefix}-modal-footer-border-color: var(--#{$prefix}border-color);
    }
    .offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
      --bs-offcanvas-bg: var(--#{$prefix}body-bg);
    }
    .nav-pills {
      --bs-nav-pills-link-active-bg: var(--#{$prefix}primary);
    }
   .card{
    --bs-card-cap-bg: var(--#{$prefix}gray-100);
    --bs-card-bg: var(--#{$prefix}gray-100);
   }

   header.header-sticky-on {
    border-bottom: $border-width solid var(--#{$prefix}border-color);
   }
    
    // Alert
    @each $color, $value in $theme-colors {
      .alert-#{$color} {
        --#{$prefix}alert-bg: rgba(#{to-rgb($value)}, .05);
        --#{$prefix}alert-border-color: rgba(#{to-rgb($value)}, .25);
        --#{$prefix}alert-color: rgba(#{to-rgb($value)}, 0.8);
      }
    }
    .alert-dark {
      --#{$prefix}alert-color: var(--#{$prefix}gray-700);
      --#{$prefix}alert-border-color: var( --#{$prefix}border-color);
    }
    .alert-secondary{
      --#{$prefix}alert-bg: var(--#{$prefix}secondary);
      --#{$prefix}alert-color: var(--#{$prefix}body-color);
      --#{$prefix}alert-link-color: #{$white};
      --#{$prefix}alert-border-color: var( --#{$prefix}border-color);
    }

    // Input group text border
    .input-group {
      .input-group-text {
        &:first-of-type:not(:last-child) {
          border-right-color: transparent;
        }
        &:last-of-type:not(:first-child) {
          border-left-color: transparent;
        }
      }
    }

    .form-control:focus{
      border-color: rgba(var(--#{$prefix}primary-rgb), 0.5);
    }
    .form-control.focus-shadow-none:focus{
      box-shadow: none;
    }
    .form-select:focus{
      border-color: rgba(var(--#{$prefix}primary-rgb), 0.5);
    }
    .form-select.focus-shadow-none:focus{
      box-shadow: none;
    }
    .bg-secondary{
      background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
    }
    .text-secondary{
      color: var(--#{$prefix}secondary) !important;
    }

    .fill-light{
      fill: var(--#{$prefix}light) !important;
    }
    .border-light{
      border-color: var(--#{$prefix}light) !important
    }
    .bg-body{
      background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
    }
    .shadow, .shadow-lg, .shadow-sm{
      box-shadow: none !important;
    }
    .navbar-nav .dropdown .dropdown-submenu:hover>.dropdown-menu {
      box-shadow: none;
    }
    .btn-close {
      filter: invert(1) grayscale(100%) brightness(300%);
    }
    .form-check-input:checked {
      background-color: var(--#{$prefix}primary);
      border-color: var(--#{$prefix}primary);
    }

    .text-primary-hover {
      a {
        color: var(--#{$prefix}gray-600);
      }
    }
    
    .text-primary-hover {
      li {
      color: var(--#{$prefix}gray-600) !important;
    }
  }
}

// Dark mode item hide and show
.dark-mode-item{
  display: none !important;
}

[data-bs-theme="dark"]{
  .dark-mode-item{
    display: inline-block !important;
  }
  .light-mode-item{
    display: none !important;
  }
}
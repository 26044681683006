// 
// pagination.scss
// Extended from Bootstrap
// 

//
// Bootstrap Overrides
//

// For better responsive
.pagination {
  .page-item{
    margin-bottom: 5px;
    float: left;
  }
}

// 
// Additional style for theme
//

//Pagination primary grad
.pagination-primary-grad{
  border: none;
  padding: 4px;
  border-radius: $border-radius;
  .page-item{
    margin: 3px;
    &.page-item.active .page-link{
      color: $white;
      background-image: linear-gradient(180deg, $grad-primary-start 0%, $grad-primary-end 100%);
    }
  }
  .page-item.disabled{
    opacity: 0.7 !important;
  }
  .page-link{
    padding: 0.5rem 1rem;
    border: transparent;
    border-radius: $border-radius !important;
    color: var(--#{$prefix}gray-700);
    background-color: transparent;
    &:hover {
      z-index: 2;
      color: $white;
      background-image: linear-gradient(180deg, $grad-primary-start 0%, $grad-primary-end 100%);
    }
  }
}

// Pagination border none
.pagination-border-none{
  .page-item.active .page-link{
    background: transparent;
    color: var(--#{$prefix}primary);
  }
  .page-link{
    padding: 0;
    border: transparent;
    background: transparent;

    &:hover{
      background: transparent;
      color: var(--#{$prefix}primary);
    }
  }
}
// 
// type.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// Background and text primary grad
.text-primary-grad{
  background: linear-gradient(90deg, $grad-primary-start 0%, $grad-primary-end 100%);
  background-clip: text;
  -WebKit-text-fill-color: transparent;
  opacity: 1;
  z-index: 9;
}

// Primary gradient color for background
.bg-primary-grad{ 
  background: linear-gradient(180deg, $grad-primary-start 0%, $grad-primary-end 100%);
}
// Secondary gradient color for background
.bg-secondary-grad{ 
  background: linear-gradient(90deg, $grad-secondary-start 0%, $grad-secondary-center 51%, $grad-secondary-end 100%)
}

// Shadow primary
.shadow-primary-sm{
  box-shadow: 0 .2rem .25rem rgba(var(--#{$prefix}primary-rgb), 0.07);
} 
.shadow-primary{
  box-shadow: 0 .2rem .25rem rgba(var(--#{$prefix}primary-rgb), 0.20);
}
.shadow-primary-md{
  box-shadow: 0 .2rem .4rem rgba(var(--#{$prefix}primary-rgb), 0.10);
}
.shadow-primary-lg{
  box-shadow: 0 .3rem .7rem rgba(var(--#{$prefix}primary-rgb), 0.20);
}

// Background blur
.bg-blur {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.bg-blur-grad {
  z-index: -1;
  opacity: 1;
  filter: blur(130px);
  background-image:linear-gradient(180deg, $grad-primary-start 0%, $grad-primary-end 100%);
  width: 100%;
  height: 360px;
}

// Link border animation
.hover-underline-animation {
  position: relative;
  &:after , &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.05em;
    bottom: 0;
    left: 0;
    background: currentcolor;
    transition: transform 0.3s ease-in-out;
  }
  &:after{
    visibility: hidden;
    transform: scaleX(0);
    transform-origin: left;
  }
  &:before{
    visibility: visible;
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0.1s; /* Delay to start after the ::after finishes */
  }
  &:hover:after {
    visibility: visible;
    transform: scaleX(1);
    transition-delay: 0.5s; /* Start immediately */
  }
  &:hover:before {
    transform: scaleX(0);
  }
}

// hover effect with opacity
.hover-opacity-fade{
  transition: $transition-base;
  .hover-item:hover{
    opacity: 1;
  }
  &:hover .hover-item{
    opacity: 0.3;
    transition: $transition-base;
  }
}
.hover-opacity-fade:hover .hover-item:hover{
  opacity: 1;
}

// Bg secondary hover
.bg-secondary-hover:hover {
  background-color: rgba(var(--#{$prefix}secondary-bg-rgb), 0.6);
}

// Background overlay. Use with bootstrap bg colors and bg opacity classes like this >> bg-overlay bg-dark bg-opacity-10
.bg-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

// Itype cursor opacity
.ityped-cursor-opacity .ityped-cursor{
  opacity: 50%;
}

.ityped-cursor-opacity.ityped-cursor-opacity .ityped-cursor{
  background: linear-gradient(90deg, $grad-primary-start 0%, $grad-primary-end 100%);
  background-clip: text;
  -WebKit-text-fill-color: transparent;
  // opacity: 1.5;
}

// Text with image
.text-bg-img{
  font-size: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  word-wrap: break-word !important;
  word-break: break-word;
}
@media (max-width: 420px) {
  .text-bg-img{
    font-size: 200px;
  }
}

//
// Emphasis
//
small,
.small {
  font-weight: $font-weight-light;
}

.smaller {
  font-size: 60%;
  font-weight: $font-weight-normal;
}

// dropcap
.dropcap{
  font-size: 4em;
  font-weight: bold;
  display: block;
  float: left;
  margin: .04em .2em 0 0;
  color: $headings-color;
  line-height: 1;
}

// Social media colors

// facebook
.bg-facebook{
  background-color: #5d82d1;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#5d82d1, 10%);
    color: $white;
  }
}
.text-facebook{
  color: #5d82d1;
  &:hover{
    color: shift-color(#5d82d1, 10%);
  }
}

// Whatsaap
.bg-whatsapp{
  background-color: #25D366;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#25D366, 10%);
    color: $white;
  }
}
.text-whatsapp{
  color: #25D366;
  &:hover{
    color: shift-color(#25D366, 10%);
  }
}

// instagram
.bg-instagram{
  background: #c22b72;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#c22b72, 10%);
    color: $white;
  }
}
.text-instagram{
  color: #c22b72;
  &:hover{
    color: shift-color(#c22b72, 10%);
  }
}

// instagram
.bg-instagram-gradient{
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: $white;
  background-size: 120% 120%;
  background-position: right bottom;
  transition: $transition-base;
  border: 0;
  &:hover, &:active, &:focus{
    background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    color: $white;
    background-size: 100% 100%;
    transition: $transition-base;
    background-position: left bottom;
  }
}
.text-instagram-gradient{
  color: transparent;
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  transition: $transition-base;
  background-size: 120% 120%;
  &:hover{
    color: transparent;
    background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 100% 100%;
    transition: $transition-base;
  }
}

// google
.bg-google{
  background-color: #3c7ff1;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#3c7ff1, 10%);
    color: $white;
  }
}
.text-google{
  color: #3c7ff1;
  &:hover{
    color: shift-color(#3c7ff1, 10%);
  }
}
.text-google-icon {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}


// twitter
.bg-twitter{
  background-color: #40bff5;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#40bff5, 10%);
    color: $white;
  }
}
.text-twitter{
  color: #40bff5;
  &:hover{
    color: shift-color(#40bff5, 10%);
  }
}

// twitter x
.bg-twitter-x{
  background-color: #000;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: tint-color(#000, 10%);
    color: $white;
  }
}
.text-twitter-x{
  color: #000;
  &:hover{
    color: tint-color(#000, 10%);
  }
}

// dribbble
.bg-dribbble{
  background-color: #ea4c89;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#ea4c89, 10%);
    color: $white;
  }
}
.text-dribbble{
  color: #ea4c89;
  &:hover{
    color: shift-color(#ea4c89, 10%);
  }
}

// linkedin
.bg-linkedin{
  background-color: #238cc8;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#238cc8, 10%);
    color: $white;
  }
}
.text-linkedin{
  color: #238cc8;
  &:hover{
    color: shift-color(#238cc8, 10%);
  }
}

// youtube
.bg-youtube{
  background-color: #ff0000;
  color: $white;
  border: none;
  &:hover, &:active, &:focus{
    background-color: shift-color(#ff0000, 10%);
    color: $white;
  }
}

.text-youtube{
  color: #ff0000;
  &:hover{
    color: shift-color(#ff0000, 10%);
  }
}

// 
// animate.scss
// Theme Component

// Hand wave animation
.hand-wave-animate {
  animation: hand-wave 2.5s infinite;
  transform-origin: 50% 80%;
  display: inline-block;
}

@keyframes hand-wave {
  0%,100% {
      transform: rotate(0)
  }

  10% {
      transform: rotate(14deg)
  }

  20% {
      transform: rotate(-8deg)
  }

  30% {
      transform: rotate(14deg)
  }

  40% {
      transform: rotate(-4deg)
  }

  50% {
      transform: rotate(10deg)
  }

  60% {
      transform: rotate(0)
  }
}

// Blink animation
.blinking-animate {
  animation: blinking 1s infinite;
}

@keyframes blinking {
  from {
    opacity: 0;
  }
}


// Zoom animation
.zoom-animate {
  animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(.9, .9);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

// Versicle move animation
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

// ripple animation
.ripple-anim{
  position: relative;
  overflow: visible;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-color: inherit;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 1;
    animation-name: rippleanim;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.5, 0, .3, 1);
    animation-delay: 1s;
  }
}
@keyframes rippleanim {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(var(--#{$prefix}primary-rgb), 0.9);
  }

  100% {
    opacity: 0;
    box-shadow: 0 0 0 20px rgba(var(--#{$prefix}primary-rgb), 0.4);
  }
}

@-webkit-keyframes rippleanim {
  0% {
    opacity: 1;
    background: rgba(var(--#{$prefix}primary-rgb), 0.9);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    background: rgba(var(--#{$prefix}primary-rgb), 0.4);
    transform: scale(1.5);
  }
}
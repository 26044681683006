// 
// card.scss
// Extended from Bootstrap
//

// 
// Additional styles for theme
// 

// Quick fix for border radius issue in safari
.card.overflow-hidden, .tiny-slider.overflow-hidden{
  will-change: transform;
}

.card-header-title{
  margin-bottom: 0;
}

// Card image overlay z index
.card{
  .card-img-overlay{
    z-index: 2;
  }
}

// Card content hover
.card-content-hover{
  .hover-content{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    padding: 40px;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    // height: 0;
    overflow: hidden;
  }
  &:hover{
    .hover-content{
      visibility: visible;
      opacity: 1;
      transition: all 0.6s ease-in-out;
      // height: 40%;
      bottom: 0;
    }
  }
}

// Card-image scale
.card.card-img-scale {
  .card-img-scale-wrapper{
    transform: translateZ(0);
    position: relative;
    overflow: hidden;
  }
  .img-scale {
    transition: all .5s ease;
    transform: scale(1.001);
  }
}
.card.card-img-scale:hover .img-scale {
  transform: scale(1.03);
}

//Card metro
.card-metro-hover{
  overflow: hidden;
  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: $transition-base;
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1));
  }
  &:hover:after {
    opacity: 1;
  }
}

// Card hover shadow
.card.card-hover-shadow{
  transition: $transition-base;
 &:hover{
  box-shadow: 0px 10px 20px rgba(var(--#{$prefix}primary-rgb), 0.15);
 }
}

// Card hover transition
.card.card-hover-transition{
  transition: $transition-base;
 &:hover{
  transform: translateY(-4px);
 }
}

// Card hover icon transition
.card.card-icon-transition .card-icon{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: $transition-base;
}
.card.card-icon-transition:hover .card-icon {
  transform: translate(-50%, -35%);
}

// Card background grad on hover
.card-bg-grad-hover{
  overflow: hidden;
    &:after {
      content: '';
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: $transition-base;
      background: linear-gradient(180deg, $grad-primary-start 0%, $grad-primary-end 100%);
    }
    &:hover:after {
      opacity: 1;
    }
}